import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import AriadneGroupNews from "../components/ariadne-group-news";
import SliderCompetence from "../components/slider-competence";

import SquareBlueWhite from "../svg/square-blue-white.svg";

const hero = [
  {
    title: "Competence center",
  },
];
const breadcrumb = [{ title: "Home", link: "/" }, { title: "Competence center" }];

const CompetenceCenter = ({ location }) => {
  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo title={`Competence center`} description={`Technical project management`} />

      <section className="section section--competence-center">
        <SquareBlueWhite className="square-blue-white d-none d-lg-block" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="box-how-do">
                <div className="row">
                  <div className="col-12 col-xl-7 offset-xl-5">
                    <div className="box-how-do__title">Technical Project Management</div>
                    <p>
                      I nostri Project Manager hanno anni di esperienza nella conduzione di progetti
                      di sistemi digitali evoluti. Con più di 17 anni di seniority in azienda, i
                      Project Manager di Ariadne si sono occupati in questi anni di progetti di
                      trasformazione digitale per aziende come Luxottica, London Stock Exchange,
                      Engie, Banco BPM, Cattolica Assicurazioni, Candy e molti altri.
                    </p>
                    <p>
                      In Ariadne adottiamo una metodologia di sviluppo ispirata ai principi lean e
                      agile per garantire qualità, efficienza e rapidità. I team di sviluppo
                      collaborano costantemente con la divisione UX per assicurare il risultato
                      migliore in termini di progettazione centrata sull’utente e tempi e costi di
                      implementazione ottimizzati.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SliderCompetence />

      <AriadneGroupNews />
    </Layout>
  );
};

export default CompetenceCenter;
